*[class^="xvia-"] {
    box-sizing: border-box;
    font-family: var(--xvia-font-family);
}

div.xvia-content-area {
  padding: 0;
}

.bgPrimary {
  background-color: var(--color-background-primary);
}

/*********
 DESIGN SYSTEM
******/

/*********
 COMPONENTS
******/

/*
====> BUTTON COMPONENT
*/

.xvia-button {
  font-family: var(--xvia-font-family);
  display: inline-block;
  color: #fff;
  border-radius: 15px;
  font-weight: 700;
  font-size: 16px;
  border: 0;
  cursor: pointer;
  min-width: 120px;
  transition: background 0.2s ease-in-out;
  width: 100%;
}

.xvia-button---primary {
  background: var(--xvia-color-primary);
  padding: 0 15px;
  height: 60px;
  line-height: 60px;
}

.xvia-button---secondary {
  color: var(--xvia-color-text-secondary);
  padding: 0 15px;
  height: 60px;
  line-height: 60px;
  font-weight: 500;
}

.xvia-button---circle {
  border-radius: 100%;
  height: 37px;
  width: 37px;
  min-width: 37px;
  padding: 0px 10px;
  line-height: 0px;
}

.xvia-button:hover {
  background: var(--xvia-color-highlight);
}

.xvia-button---secondary:hover {
  background: var(--xvia-color-primary);
  color: white;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .xvia-button {
    width: 100%;
  }
}

/*
====> TITLE COMPONENT
*/

.xvia-title-h {
  font-family: var(--xvia-font-family);
  color: var(--xvia-color-title);
  font-weight: 700;
  margin-bottom: 20px;
}

.xvia-title--no-margin {
  margin: 0;
}

.xvia-title-h5 {
  font-size: var(--font-title-h5);
}

/*
====> PARAGRAPH COMPONENT
*/

.xvia-paragraph {
  font-family: var(--xvia-font-family);
  color: var(--xvia-color-text);
  padding-right: 6px;
  line-height: 28px;
}

/*
====> TAG COMPONENT
*/

.xvia-tag {
    font-family: var(--xvia-font-family);
    font-size: 12px;
    color: var(--color-grey-10);
    text-decoration: none;
    font-weight: 500;
    line-height: 1;    
    padding: 5px 14px;
    background: var(--color-background-dark);
    display: inline-block;
    margin-right: 11px;
    border-radius: 10px;
    margin-bottom: 16px;
    transition: all 0.5s linear 0s;
}

.xvia-tag:hover {
    background-color: var(--color-grey-10);
    color: var(--color-primary);
}

/*
====> STEP COMPONENT
*/

.xvia-step__header {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.xvia-step__content {
  margin-left: 0px;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-in-out;
}

.xvia-step--active .xvia-step__content {
  max-height: 1000px;
}

.xvia-step__number {
  width: 28px;
  height: 28px;
  background: var(--color-grey-90);
  color: var(--color-background-primary);
  display: inline-block;
  line-height: 28px;
  text-align: center;
  border-radius: 50px;
  margin-right: 16px;
  font-family: var(--xvia-font-family);
  font-size: 16px;
  font-weight: 700;
}

/*
====> ACCORDION COMPONENT
*/

.xvia-accordion__header {
  border: 0;
  padding: 16px 24px;
  display: flex;
  width: 100%;
  text-align: left;
  background-color: white;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}

.xvia-accordion__header .xvia-title-h {
  flex-grow: 1;
}

.xvia-accordion__content {
  max-height: 0;
  padding: 0 16px;
  overflow: hidden;
  transition: max-height 0.2s ease-in-out;
  font-family: var(--xvia-font-family);
  color: var(--xvia-color-text);
  background-color: white;
  margin-bottom: 8px;
}

.xvia-accordion__item--active .xvia-accordion__content {
  max-height: 500px;
  padding-bottom: 16px;
}

/*
====> CARD COMPONENT
*/

/*
====> BANNER SLIDER
*/

.xvia-gov-news__slider_imgs {
    padding: 0 5px;
    border-radius: 10px;
    margin: 0;
  
    > img {
      width: 100%;
      border-radius: 10px;
      height: 250px;
      object-fit: fill;
    }
  }

/*
====> CARD COMPONENT
*/

.xvia-card {
  border: 2px solid #f3f3f3;
  padding: 18px;
  padding-bottom: 38px;
  background-color: white;
}

.xvia-card__title {
  line-height: 1;
  font-weight: 700;
  font-size: 20px;
  margin-bottom: 30px;
  padding-bottom: 20px;
  color: #181b1d;
  border-bottom: 1px solid #f1f1f1;
}

.xvia-card__title span {
  color: var(--xvia-color-primary);
}

.xvia-card-section__map {
  border: 0px;
  width: 100%;
  height: 300px;
  padding: 30px 10px;
}

.xvia-card__borderless-bottom {
  border-bottom: unset;
  padding-bottom: 18px;
}

/*
====> SERVICE COMPONENT
*/

.xvia-service-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.04);
  border-radius: 5px;
  background: #fff;
  text-align: left;
  border: none;
  padding: 24px;
  flex: 1 1 48%;

  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.04), 0px -6px 11px rgba(0, 0, 0, 0.02);

  footer {
    align-self: flex-end;

    button {
      background-color: var(--xvia-color-primary);
      border: none;
      border-radius: 5px;
      box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.15);
      padding: 8px 18.5px;

      font-family: var(--xvia-font-family);
      font-weight: 500;
      color: white;
      font-size: 14px;
      line-height: 15px;
      letter-spacing: 0.46px;

      cursor: pointer;

      -webkit-transition: background-color ease-out 0.3s,
        font-weight ease-out 0.3s;
      -moz-transition: background-color ease-out 0.3s, font-weight ease-out 0.3s;
      -ms-transition: background-color ease-out 0.3s, font-weight ease-out 0.3s;
      -o-transition: background-color ease-out 0.3s, font-weight ease-out 0.3s;
      transition: background-color ease-out 0.3s, font-weight ease-out 0.3s;
    }

    button:hover {
      background-color: #ff891b;
      font-weight: 700;
    }
  }
}

.xvia-service-card:hover {
  border: 2px solid var(--xvia-color-primary);
  padding: 22px;

  h4,
  .xvia-service-card__description {
    font-weight: 700;
  }
}

.xvia-service-card__description {
  font-family: var(--xvia-font-family);
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.46px;
  color: #7b7b7b;
  margin-bottom: 12px;

  -webkit-transition: font-weight ease-out 0.3s;
  -moz-transition: font-weight ease-out 0.3s;
  -ms-transition: font-weight ease-out 0.3s;
  -o-transition: font-weight ease-out 0.3s;
  transition: font-weight ease-out 0.3s;
}

.xvia-service-card__targets {
  display: flex;
  gap: 16px;
  flex-wrap: wrap;

  svg {
    margin-right: 6px;
  }
}

.xvia-service-card__tag {
  border: none;
  color: #777777;
  border-radius: 10px;
  background-color: white;
}

.xvia-service-card__tag.Empresa {
  color: #0661a7;
  border: 0.5px solid #0661a7;
}

.xvia-service-card__tag.Servidor {
  color: #02add5;
  border: 0.5px solid #02add5;
}

/*
====> LISTING COMPONENT
*/

.xvia-listing-card {
  position: relative;
  box-shadow: 0px 10px 50px 0px rgba(0, 0, 0, 0.04);
  border-radius: 5px 5px 0 0;
  background: #fff;
  width: 100%;
  text-align: left;
  border: none;
  cursor: pointer;
  padding: 0;
  margin: 0;
}

.xvia-listing-card__header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 4px;
}

.xvia-listing-card__department {
  font-family: var(--xvia-font-family);
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.45px;
  align-items: center;
  gap: 8px;
  flex: 1;
  white-space: normal;
}

.xvia-listing-card__service-type {
  font-size: 14px;
  font-weight: 500;
  font-family: var(--xvia-font-family);
  line-height: 20px;
  letter-spacing: 0.45px;
  color: #4a4a4a;
  display: flex;
  align-items: center;
  gap: 8px;
  white-space: nowrap;
  flex-shrink: 0;
}

.xvia-listing-card__line {
  max-width: 100%;
}

.xvia-listing-card---focused {
  transform: scale(1.01);
}

.xvia-listing-card__content {
  position: relative;
  padding: 27px 30px 15px;
  border-radius: 0 0 15px 15px;
}

.xvia-listing-card__line .xvia-listing-card__content {
  width: 100%;
}

.xvia-listing-card__header-buttons {
  position: absolute;
  right: 30px;
  top: 0;
  transform: translateY(-50%);
  z-index: 3;
}

.xvia-listing-card__rating {
  font-size: 12px;
  color: var(--xvia-color-primary);
  margin-bottom: 10px;
}

.xvia-listing-card__title {
  margin-bottom: var(--xvia-spacing-tight);
  font-family: var(--xvia-font-family);
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.46000000834465027px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.xvia-listing-card__info {
  margin: 4px 0;
}

.xvia-listing-card__bottom {
  border-top: 1px solid #f1f1f1;
}

.xvia-listing-card__bottom-button {
  max-width: 146px;
  max-height: 31px;
  border-radius: 5px;
  font-family: var(--xvia-font-family);
  font-size: 14px;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: 0.45px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.xvia-listing-card__subtitle {
  display: -webkit-box;
  --webkit-box-orient: vertical;
  --webkit-line-clamp: 4;
  overflow: hidden;

  font-family: var(--xvia-font-family);
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.46000000834465027px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

/*
====> STAR
*/

.xvia-rating {
  color: var(--xvia-color-primary);
}

.xvia-rating__icon {
  margin-right: 4px;
  transition: opacity 0.2s ease-in-out;
}

.xvia-rating__icon--disabled {
  opacity: 0.1;
}

.xvia-rating__icon--clickable {
  cursor: pointer;
}

/*
====> LIKE BUTTON
*/

.xvia-icon-button {
  color: #fff;
  background: var(--xvia-color-primary);
  padding: 8px;
  line-height: 12px;
  border-radius: 50%;
  border: 0;
  cursor: pointer;
  transition: all 0.2s linear 0s;
}

.xvia-icon-button:hover {
  transform: scale(1.5);
}

.xvia-icon-button--flat {
  margin-right: 4px;
  background: transparent;
  color: var(--xvia-color-primary);
}

/*
====> NEWS CARD
*/

.xvia-news-card {
  position: relative;
  box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.04);
  border-radius: 15px;
  background: #fff;
  width: 100%;
  text-align: left;
  border: none;
  cursor: pointer;
  padding: 0;
  max-width: 500px;
  overflow: hidden;
}

.xvia-news-card__thumb {
  overflow: hidden;
  border-radius: 15px 15px 0 0;
  height: 200px;
  background-size: cover;
  background-position: center;
}

.xvia-news-card__thumb {
  transition: all 0.3s linear 0s;
  align-items: flex-end;
  display: flex;
}

.xvia-news-card__thumb:hover {
  transform: scale(1.1);
}

.xvia-news-card__content {
  background: #0000009c;
  height: 80px;
  position: relative;
  color: white;
  --webkit-line-clamp: 3;
  --webkit-box-orient: vertical;
  overflow: hidden;
  padding: 15px;
  line-height: initial;
  font-weight: bold;
  margin-top: -80px;
}

/*
====> CATALOG CARD
*/

.xvia-catalog-card {
  border-radius: 15px;
  background: #fff;
  width: 100%;
  padding: 50px;
}

.xvia-catalog-card__info_content span {
  display: inline-block;
  padding: 5px;
}

/*
====> FORM
*/

.xvia-form-input {
  height: 65px;
  width: 100%;
  background-color: #f4f4f4;
  border: 0;
  padding: 0 30px;
  font-size: 16px;
  color: #a7a7a7;
  border-radius: 16px;
  transition: opacity 0.3s linear 0s;
}

.xvia-form-input--disabled {
  opacity: 0.4;
}

/*
====> SELECT
*/

.xvia-form-input-select {
  height: 65px;
  width: 100%;
  background-color: #f4f4f4;
  border: 0;
  padding: 0 30px;
  font-size: 16px;
  color: #a7a7a7;
  border-radius: 16px;
  transition: opacity 0.3s linear 0s;
  --moz-appearance: none; /* Firefox */

  margin: 0;
  --webkit-box-sizing: border-box;
  --moz-box-sizing: border-box;
  box-sizing: border-box;
  --webkit-appearance: none;
  --moz-appearance: none;

  background-image: linear-gradient(45deg, transparent 50%, gray 50%),
    linear-gradient(135deg, gray 50%, transparent 50%),
    radial-gradient(#ddd 70%, transparent 72%);
  background-position: calc(100% - 20px) calc(1em + 15px),
    calc(100% - 15px) calc(1em + 15px), calc(100% - 0.5em) calc(1em + 4px);
  background-size: 5px 5px, 5px 5px, 1.5em 1.5em;
  background-repeat: no-repeat;
}

.xvia-form-input-select:focus {
  background-image: linear-gradient(45deg, white 50%, transparent 50%),
    linear-gradient(135deg, transparent 50%, white 50%),
    radial-gradient(gray 70%, transparent 72%);
  background-position: calc(100% - 15px) calc(1em + 14px),
    calc(100% - 20px) calc(1em + 14px), calc(100% - 0.5em) calc(1em + 4px);
  background-size: 5px 5px, 5px 5px, 1.5em 1.5em;
  background-repeat: no-repeat;
  border-color: green;
  outline: 0;
}

/*
====> INFO
*/

.xvia-info {
  display: inline-block;
}

.xvia-info__icon {
  color: var(--xvia-color-primary);
  margin-right: 4px;
  font-size: 14px;
}

.xvia-info__text {
  font-family: var(--xvia-font-family);
  font-size: 14px;
  color: var(--xvia-color-text);
  font-weight: 500;
}

/*
====> BREAK PAGE
*/

.xvia-break-page {
  flex-direction: row;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.xvia-break-page__icon {
  height: 20px;
  width: 5px;
  margin-right: 10px;
}

.xvia-break-page__text {
  font-family: var(--xvia-font-family);
  font-size: 22px;
  color: var(--xvia-color-text);
  font-weight: bold;
  margin-right: 20px;
}

.xvia-break-page__line {
  color: var(--xvia-color-text);
  width: 70%;
  background-color: #ccc;
  height: 2px;
}

/*
====> DOCUMENT LIST
*/

.xvia-document-list {
  list-style-type: none;
  padding-left: 0;
}

.xvia-document-list .xvia-info__icon {
  margin-right: 18px;
}

.xvia-document-list__item {
  padding: 16px;
  border-bottom: 1px solid #efefef;
  font-weight: 800;
}

.xvia-document-list__item:last-child {
  border-bottom: none;
}

/*
====> INFO CARD
*/

.xvia-metrics-card {
  padding: 40px;
  border-radius: 15px;
  background: #fff;
  text-align: center;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
  box-shadow: 0px 16px 32px 0px rgb(171 171 171 / 10%);
  font-family: var(--xvia-font-family);
  color: var(--xvia-color-text);
  max-width: 500px;
}

.xvia-metrics-card__icon {
  width: 40px;
  height: 40px;
  line-height: 95px;
  font-size: 10px;
  color: #fff;
  background: var(--xvia-color-primary);
  margin: 0 auto 20px;
  border-radius: 50%;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
  font-weight: 200;
  padding: 25px;
}

.xvia-metrics-card__text {
  color: #181b1d;
  font-size: 48px;
  font-weight: 700;
  margin-bottom: 0;
  line-height: 38px;
  margin-top: 0px;
}

.xvia-metrics-card:hover {
  background: var(--xvia-color-primary);
  color: #ffffff;
}

.xvia-metrics-card:hover .xvia-metrics-card__icon {
  background: #fff;
  color: var(--xvia-color-primary);
}

.xvia-metrics-card:hover .xvia-metrics-card__icon {
  color: #b2b2b2;
}

/*
====> CATEGORY CARD
*/

.xvia-category-card {
  padding: 15px 20px;
  border-radius: 15px;
  background: #fff;
  text-align: center;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
  box-shadow: 0px 16px 32px 0px rgb(171 171 171 / 10%);
  font-family: var(--xvia-font-family);
  color: var(--xvia-color-text);
  width: 200px;
  height: 150px;
  display: inline-block;
  margin: 10px;
  cursor: pointer;
}

.xvia-category-card__icon {
  width: 20px;
  height: 20px;
  line-height: 85px;
  font-size: 10px;
  color: #fff;
  background: var(--xvia-color-primary);
  margin: 0 auto 10px;
  border-radius: 50%;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s;
  font-weight: 200;
  padding: 25px;
}

.xvia-category-card:hover {
  background: var(--xvia-color-primary);
  color: #ffffff;
}

.xvia-category-card:hover .xvia-category-card__icon {
  background: #fff;
  color: var(--xvia-color-primary);
}

.xvia-category-card:hover .xvia-category-card__icon {
  color: #b2b2b2;
}

/*
====> CAROUCEL COMPONENT
*/

.xvia-carousel .slick-list {
  padding: 20px 0px;
  overflow: hidden;
}

.slick-next:before,
.slick-prev:before {
  font-size: 30px;
  line-height: 1;
  opacity: 0.75;
  color: #6e6e6e;
}

.xvia-carousel .slick-slide {
  display: inline-block;
}

.xvia-carousel-icon {
  font-size: 24px;
  color: #c2c2c2;
}

.xvia-carousel-icon:hover {
  font-size: 24px;
  color: var(--xvia-color-primary);
}

/*.xvia-carousel .slick-arrow{*/
/*    font-size: 0;*/
/*    line-height: 0;*/
/*    position: absolute;*/
/*    top: 50%;*/
/*    display: block;*/
/*    width: 20px;*/
/*    height: 20px;*/
/*    padding: 0;*/
/*    -ms-transform: translateY(-50%);*/
/*    transform: translateY(-50%);*/
/*    cursor: pointer;*/
/*    border: none;*/
/*}*/
/*.xvia-carousel{}*/
/* .slick-next {*/
/*     right: -25px;*/
/* }*/
/*.slick-prev {*/
/*    left: -25px;*/
/*}*/

/*
====> SEARCH INPUT
*/

.xvia-search-input {
  background: var(--color-input-background);
  border: var(--color-input-border);
  padding: 4px 4px;
  border-radius: 15px;
}

.xvia-catalog-search-input__input {
  width: 100%;
  padding-left: 30px;
  font-size: var(--font-search-input);
  font-weight: 300;
  color: #c5c5c5;
  border: none;
}

.xvia-catalog-search-input__input-list {
  font-size: var(--font-search-input);
}

.xvia-search-input__button {
  height: 48px;
  line-height: 48px;
  font-size: 14px;
}

@media screen and (max-width: 768px) {
  .xvia-catalog-search-input__input {
    padding-left: 12px;
    margin: 12px 0;
  }
}

@media (min-width: 768px) {
  div.xvia-search-input__button-wrapper {
    flex: 0 0 100px;
  }
}

/*
====> SOCIAL MIDIA
*/

.xvia-social-midia {
  display: flex;
  width: 268px;
  justify-content: space-between;
}

.xvia-social-midia__content_icon {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--xvia-color-darkness-blue);
  border-radius: 50%;
  border: 1px solid white;
  width: 55px;
  height: 55px;
}

.xvia-social-midia__content_icon:first-child {
  margin-left: 0px;
}

/*
====> LINK LIST
*/

.xvia-link-list {
  margin-right: 24px;

  &:first-of-type {
    width: 362px;
  }

  &:last-of-type {
    margin-right: 0;

    .xvia-link-list__ul {
      justify-content: flex-end;
    }
  }
}

.xvia-link-list__ul {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 8px 24px;
  height: 292px;

  list-style: none;
  padding: 0;
  margin: 0;

  dd {
    margin: 0;
  }

  a {
    display: flex;
    max-width: 365px;

    font-family: var(--xvia-font-family);
    font-weight: 300;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.46px;

    svg {
      max-height: 22px;
    }
  }
}

.xvia-link-list__title {
  display: flex;
  align-items: center;
  margin-bottom: 16px;

  &::before {
    content: '';
    height: 30px;
    width: 3px;
  }

  > svg {
    margin: 0 8px;
  }

  > span {
    font-family: var(--xvia-font-family);
    font-weight: 300;
    font-size: 18px;
    letter-spacing: 2px;
    line-height: 30px;
  }
}

.xvia-app-card {
  position: relative;
  border-radius: 15px;
  background-color: white;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  /*max-width: 200px;*/
  min-height: 200px;
  /*padding: 60px;*/
}

.xvia-app-card:hover div {
  visibility: initial;
  background-color: var(--xvia-color-primary);
}

.xvia-app-card__title {
  color: white;
  margin: 10px 0px;
}

.xvia-app-card__icon {
  font-size: 33px;
  color: white;
}

/*
====> BREADCRUMB COMPONENT
*/
.xvia-breadcrumb {
  background: inherit;
  color: #fff;
}

/*
====> CONTAINER
*/
.xvia-container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 768px) {
  .xvia-container {
    width: 750px;
  }
}

@media (min-width: 992px) {
  .xvia-container {
    width: 970px;
  }
}

@media (min-width: 1200px) {
  .xvia-container {
    width: 1170px;
  }
}

/*********
 MODULES
**********/
.xvia-home-search__container {
	display: flex;
}

.xvia-department-search__input input:focus {
  outline: none;
}

.xvia-department-search__input input {
  height: 45px;
}

@media (min-width: 768px) {
    .xvia-home-search__input {
        margin: auto;
    }

    .xvia-home-search .xvia-home-search__links {
        display: inline-flex;
    }

    .xvia-home-search__links {
        max-width: 66%;
        margin: auto;
    }
}

/*********
 MODULES - Recommended Services
**********/

.xvia-recommended-services {
  padding: 60px;
  background-color: white;
}

.xvia-recommended-services__container {
  text-align: center;
}

.xvia-recommended-services__title {
  font-size: 64px;
  color: #181b1d;
  margin-bottom: 40px;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .xvia-recommended-services__title {
    font-size: 60px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .xvia-recommended-services__title {
    font-size: 50px;
  }
}

.xvia-recommended-services__subtitle {
  color: #777777;
  font-size: 16px;
  display: block;
  font-weight: 700;
  margin-bottom: 0px;
}

.xvia-recommended-services__card {
  display: inline-block;
  margin: 0px 10px;
  text-align: center;
}

/*********
 MODULES - Relevant Services
**********/

.xvia-relevant-services {
  background-color: white;
  padding: 20px;
}

.xvia-relevant-services__container {
  text-align: center;
}

.xvia-relevant-services__loading {
  height: 317px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .xvia-relevant-services {
    padding: 60px 30px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .xvia-relevant-services {
    padding: 60px 2px;
  }
}

@media (max-width: 767px) {
  .xvia-relevant-services {
    padding: 60px 0px;
  }
}

/*********
 MODULES - Relevant Services
**********/

.xvia-news {
  padding: 20px;
  background-color: white;
}

.xvia-news__container {
  text-align: center;
}

.xvia-news__card {
  display: inline-block;
  margin: 0px 10px;
  text-align: center;
}

/*********
 MODULES - Relevant Services
**********/

.xvia-indicators {
  padding: 60px 60px 0px 60px;
  background-color: var(--xvia-color-primary);
  height: 245px;
}

.xvia-indicators__container {
  text-align: center;
}

.xvia-indicators__title {
  font-size: 64px;
  color: #ffffff;
  margin-bottom: 40px;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .xvia-indicators__title {
    font-size: 60px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .xvia-indicators__title {
    font-size: 50px;
  }
}

@media (max-width: 767px) {
  .xvia-indicators__title {
    font-size: 40px;
  }

  .xvia-indicators {
    height: auto;
    padding-bottom: 20px;
  }
}

.xvia-indicators__subtitle {
  color: #ffffff;
  font-size: 16px;
  display: block;
  font-weight: 700;
  margin-bottom: 0px;
}

.xvia-indicators__card {
  display: inline-block;
  margin: 0px 10px;
  text-align: left;
}

/*********
 MODULES - Apps
**********/

.xvia-apps {
  margin-bottom: 40px;
  padding: 40px;
  background-color: white;
}

.xvia-apps__container {
  text-align: center;
}

.xvia-apps__card {
  display: inline-block;
  margin: 0px 10px;
  text-align: center;
}

/*********
 MODULES - Ombudsman
**********/

.xvia-ombudsman {
  margin-top: 80px;
  padding: 60px;
  background-color: white;
}

.xvia-ombudsman__container {
  text-align: center;
}

.xvia-ombudsman__card {
  display: inline-block;
  margin: 0px 10px;
  text-align: center;
}

/*********
 MODULES - Footer
**********/

.xvia-footer {
  padding: 60px 150px 0;
  background-color: var(--xvia-color-darkness-blue);
  color: #fff;

  hr {
    margin: 24px 0;
    border-color: white;
  }

  a {
    color: #fff;
  }
}

.xvia-footer__logo {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.xvia-footer__links {
  display: flex;
  margin-bottom: 24px;
}

@media screen and (max-width: 990px) {
  .xvia-footer__logo {
    flex-direction: column;
    gap: 30px;
    margin: 0 0 30px 0;
  }

  .xvia-footer__contact {
    margin: 50px 0 0 0;
  }

  .xvia-footer__links {
    flex-direction: column;
    gap: 50px;
  }

  .xvia-link-list__ul {
    display: flex;
    flex-direction: column;
    gap: 8px 24px;
    height: 100%;
  }

  .xvia-footer__contact__list {
    flex-direction: column-reverse;
    gap: 20px;
  }

  .xvia-service-cards {
    padding: 20px 30px !important;
  }

  .xvia-welcome {
    padding: 20px 30px 0px;
  }

  .xvia-footer {
    padding: 60px 20px 0;
  }

  .xvia-welcome__text {
    h1 {
      font-size: 28px;
    }

    span {
      font-size: 18px;
    }
  }

  .xvia-link-bar {
    justify-content: center;

    button {
      height: 130px;
    }
  }

  .xvia-gov-programs__container {
    clip-path: ellipse(140% 75% at 50% 20%) !important;
  }

  .xvia-wave::before {
    clip-path: ellipse(75% 70% at 50% 20%) !important;
  }

  .divider-encapsulated__content {
    clip-path: ellipse(160% 80% at 50% 20%) !important;
  }

  .divider-encapsulated__divider-top {
    clip-path: ellipse(70% 80% at 50% 20%) !important;
  }

  .xvia-gov-news__container_tabs_content {
    margin: 0 !important;
  }

  .xvia-page-banners-container {
    margin: 0 auto 0 0 !important;
  }
}

.xvia-footer__contact__list {
  display: flex;
  justify-content: space-between;

  div {
    width: 364px;

    span {
      vertical-align: top;
      display: inline-block;
      margin-left: 8px;
      white-space: pre-line;
    }
  }
}

.xvia-footer__footer {
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: #06214c;
  min-height: 100px;

  font-family: var(--xvia-font-family);
  font-weight: 500;
  font-size: 14px;
  line-height: 28px;
  letter-spacing: 0.22em;

  a {
    color: white;
  }
}

/*********
 MODULES - LinkBar
**********/

.xvia-link-bar {
  margin-top: 32px;
  display: flex;
  flex-wrap: wrap;
  white-space: pre-line;
  justify-content: space-between;
  gap: 24px;

  button {
    flex: 1;
    border: 0;
    display: inline-block;
    padding: 30px 15px;
    width: 267px;
    border-radius: 5px;
    height: 180px;

    color: #202020;
    font-family: var(--xvia-font-family);
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.46px;

    cursor: pointer;

    --webkit-transition: background-color 0.3s ease-out,
      font-weight 0.3s ease-out, color 0.3s ease-out;
    --moz-transition: background-color 0.3s ease-out, font-weight 0.3s ease-out,
      color 0.3s ease-out;
    --o-transition: background-color 0.3s ease-out, font-weight 0.3s ease-out,
      color 0.3s ease-out;
    transition: background-color 0.3s ease-out, font-weight 0.3s ease-out,
      color 0.3s ease-out;

    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.04),
      0px -6px 11px rgba(0, 0, 0, 0.02);

    svg {
      margin-bottom: 16px;

      --webkit-transition: color 0.3s ease-out;
      --moz-transition: color 0.3s ease-out;
      --o-transition: color 0.3s ease-out;
      transition: color 0.3s ease-out;
    }
  }

  button:hover {
    color: #fff;
  }
}

/*********
 MODULES - Footer
**********/

.xvia-catalog__breadcrumb {
  padding-top: 1rem;
  padding-bottom: 1rem;
  background-color: #fff;
}

.xvia-catalog__hearder_container {
  height: 120px;
  background-color: var(--xvia-color-primary);
  width: 100%;
  margin-bottom: -100px;
  padding-top: 130px;
}

.xvia-catalog__info_section {
  padding-top: 40px;
  padding-bottom: 40px;
  border-bottom: 1px solid #efefef;
}

.xvia-catalog__info_section h2 {
  padding-bottom: 15px;
}

.xvia-catalog__info_row {
  word-break: break-word;
  margin-bottom: 30px;
}

.xvia-catalog__section_list {
  max-height: 200px;
  overflow: auto;
  width: 100%;
  padding: 20px;
}
.xvia-catalog__info_row_cannel {
  font-size: 20px;
  color: var(--xvia-color-primary);
  margin-bottom: 20px;
  border-bottom: 1px solid #eaeaea;
  padding-left: 10px;
  padding: 10px;
}
.xvia-catalog__info_row_cannel span {
  font-size: 14px;
  color: var(--xvia-color-text);
}

/*********
 COMPONENT - Wave
**********/

.xvia-wave::before {
  display: block;
  height: var(--before-height);
  content: '';
  clip-path: ellipse(55% 80% at 50% 20%);
  background-color: var(--before-color);
}

/*********
 MODULES - Category
**********/

.xvia-category-services__container {
  text-align: center;
}

/*********
 MODULES - List
**********/

.xvia-list {
  margin-top: 0px;
}

.xvia-list__header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 16px;
}

@media (max-width: 768px) {
  .xvia-list__header-container {
    flex-direction: column;
    align-items: flex-start;
  }

  .xvia-list__header-container button {
    align-self: stretch;
  }
}

.xvia-list__breadcrumb {
  padding-top: 1rem;
  padding-bottom: 1rem;
  background-color: #fff;
}

.xvia-list__head_info {
  background-size: cover;
  background-position: center;
  opacity: 0.3;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.xvia-list__head_content {
  /*height: 180px;*/
  overflow: hidden;
  position: relative;
  background-color: var(--xvia-color-primary);
  margin-bottom: 60px;
}

.xvia-list__head_info_container {
  padding: 30px 0px;
  position: relative;
}

.xvia-list__head_info__title p {
  font-size: 20px;
  margin-top: -25px;
}

.xvia-list__head_info__title {
  font-size: 64px;
  margin-bottom: 0px;
  color: white;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .xvia-list__head_info__title {
    font-size: 60px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .xvia-list__head_info__title {
    font-size: 50px;
  }
}

@media (max-width: 767px) {
  .xvia-list__head_info__title {
    font-size: 40px;
  }
}

.xvia-list__head_info__subtitle {
  color: white;
  font-size: 16px;
  display: block;
  font-weight: 700;
  margin-bottom: 0px;
}

.xvia-pagination {
  margin-top: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0px;
  flex-wrap: nowrap;
}

.xvia-pagination .ant-pagination-item {
  border: none;
  background: none;
  width: auto;
  height: auto;
  padding: 0;
  font-size: 16px;
  color: #007bff;
  line-height: normal;
}

.xvia-pagination .ant-pagination-item-active {
  background: none;
  font-weight: bold;
  color: #ff891b;
}

.xvia-pagination .ant-pagination-prev .ant-pagination-item-link,
.xvia-pagination .ant-pagination-next .ant-pagination-item-link {
  border: 2px solid #007bff;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  color: #007bff;
  transition: all 0.3s ease;
}

.xvia-pagination .ant-pagination-prev:hover .ant-pagination-item-link,
.xvia-pagination .ant-pagination-next:hover .ant-pagination-item-link {
  background: #f0f0f0;
  color: #0056b3;
  border-color: #0056b3;
}

.xvia-pagination .ant-pagination-item-ellipsis {
  color: #7b7b7b;
  font-size: 16px;
}

/*********
 MODULES - Department
**********/

.xvia-department__breadcrumb {
  padding-top: 1rem;
  padding-bottom: 1rem;
  background-color: #fff;
}

.xvia-department__info_section {
  padding-top: 40px;
  padding-bottom: 40px;
  border-bottom: 1px solid #efefef;
}

.xvia-department__info_section h2 {
  padding-bottom: 15px;
}

.xvia-department__pagination {
  padding-top: 40px;
}

/*
====> MODAL REVIEW
*/
.modal-review__button {
  font-family: var(--xvia-font-family);
  color: #fff;
  border: 0;
  cursor: pointer;
  transition: background 0.2s ease-in-out;
}

.modal-review__button--primary {
  background: var(--xvia-color-primary);
}

.modal-review__button--secondary {
  color: var(--xvia-color-text-secondary);
}

.modal-review__button:hover {
  background: var(--xvia-color-highlight);
}

.modal-review__button--secondary:hover {
  background: var(--xvia-color-gray);
  color: var(--xvia-color-text-secondary);
}

.modal-review__rating {
  display: flex;
  column-gap: 10px;
}

.modal-review__comment {
  margin-top: 15px;
}

.xvia-service-cards {
  display: flex;
  flex-direction: column;
  font-family: var(--xvia-font-family);
  font-weight: 500;
  background-color: #e5e7eb;

  padding: 40px 150px 0;

  h2 {
    margin: 0;
    font-size: 26px;
    line-height: 36px;
    letter-spacing: 1px;
    color: #202020;
    font-family: var(--xvia-font-family);
  }

  span {
    font-size: 20px;
    line-height: 26px;
    color: #7b7b7b;
    letter-spacing: 1px;
  }
}

.xvia-service-cards__head {
  height: 62px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;

  > button {
    background-color: var(--xvia-color-primary);
    border-radius: 5px;
    border: 0;
    height: 40px;
    padding: 14px 47px;

    color: #fff;
    font-weight: 500;
    line-height: 15px;

    cursor: pointer;

    --webkit-transition: background-color 0.3s ease-out,
      font-weight 0.3s ease-out;
    --moz-transition: background-color 0.3s ease-out, font-weight 0.3s ease-out;
    --o-transition: background-color 0.3s ease-out, font-weight 0.3s ease-out;
    transition: background-color 0.3s ease-out, font-weight 0.3s ease-out;

    > svg {
      margin-right: 8px;
    }
  }
}

.xvia-service-cards__head__content {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  > svg {
    margin: 0 24px;
  }
}

.xvia-service-cards__head__text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.xvia-service-cards__bottom-border {
  height: 100px;
  clip-path: ellipse(55% 80% at 50% 20%);
  background-color: #e5e7eb;
}

/*
====> Location
*/

.xvia-location__map {
  border: 0px;
  width: 100%;
  height: 250px;
  margin-top: 10px;
  border-radius: 5px;
  box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.04);
}

.xvia-location__search-container {
  display: flex;
  weight: 100%;
  height: 44px;
  background-color: #fff;
  border-radius: 5px;
  margin-top: 1.5rem;
  align-items: center;
  padding: 1rem;
  gap: 1rem;
}

.xvia-location__search-input {
  background: none;
  box-shadow: none;
}

/*
====> Extra Section
*/

.xvia-catalog__extra-section-container {
  background-repeat: no-repeat;
  background-size: 1460px;
  background-position: top;
  padding-top: 85px;
}

/*
====> Contact Us Section
*/

.xvia-catalog-container > .xvia-footer {
  margin-top: -72px;
  padding-top: 135px;
}

@media (min-width: 1200px) {
  .xvia-catalog-container > .xvia-footer {
    margin-top: -92px;
  }
}

/*
====> Box Square
*/

.xvia-box-square__container {
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 1.5rem;
}

.xvia-box-square__info-create {
  display: flex;
  gap: 16px;
}

.xvia-box-square__info-create svg {
  color: var(--xvia-color-secondary);
  weight: 16px;
  height: 16px;
}

.xvia-box-square__info-create-item {
  display: flex;
  align-items: center;
  gap: 2px;
  color: var(--xvia-color-grey-90);
  font-size: 14px !important;
  font-family: var(--xvia-font-family);
}

.xvia-box-square__title {
  font-size: 26px;
  line-height: 36px;
  font-weight: 500;
  font-family: var(--xvia-font-family);
  color: var(--xvia-color-grey-100);
}

.xvia-box-square__description {
  color: var(--xvia-color-grey-90);
  font-family: var(--xvia-font-family);
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
}

/*
====> Icon Text
*/

.xvia-icon-text__container {
  display: flex;
  gap: 16px;
  align-items: center;
  color: var(--xvia-color-grey-80);
  cursor: pointer;
  font-family: var(--xvia-font-family);
  font-size: 14px;
  font-weight: 500;
  transition: 'all 0.3s ease';

  &:hover {
    color: var(--xvia-color-grey-100);

    svg {
      color: #fff;
    }

    .xvia-icon-text__icon {
      background-color: var(--xvia-color-primary);
    }

    .xvia-icon-text__icon-action {
      svg {
        color: var(--xvia-color-secondary);
      }
    }
  }
}

.xvia-icon-text__container svg {
  color: var(--xvia-color-grey-70);
}

.xvia-icon-text__icon {
  display: flex;
  width: 45px;
  height: 45px;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: 50%;
  box-shadow: 4px 4px 8px 0px #0000000a;
}

.xvia-icon-text__text {
  display: flex;
  flex: 1;
}

.xvia-icon-text__icon-action {
  display: flex;
  align-items: center;
  justify-content: center;
}

/*
====> Title Sections
*/

.xvia-title-section__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 0;
  display: flex;
  justify-content: space-between;
}

.xvia-title-section__button svg {
  color: #fff;
}

.xvia-title-section__block_title {
  display: flex;
  align-items: center;

  > svg {
    margin: 0 24px;
  }
}

.xvia-title-section__block_button {
  display: flex;
  align-items: center;
}

.xvia-title-section__block_button svg {
  margin: 0 5px 0 0 !important;
}

.xvia-title-section__text_content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  > h2 {
    font-size: 26px;
    line-height: 36px;
    letter-spacing: 1px;
    color: #202020;
    font-family: var(--xvia-font-family);
  }

  > span {
    font-size: 20px;
    line-height: 26px;
    color: #7b7b7b;
    letter-spacing: 1px;
  }
}

.xvia-link-list__title::before,
.xvia-title-section__bar {
  background-color: #ff891b;
  border-radius: 10px;
  box-shadow: -3 3px 10px 0px rgba(0, 0, 0, 1);
}

.xvia-title-section__bar {
  height: 70px;
  width: 6px;
}

.xvia-gov-programs__img_slide {
  padding: 10px;
  background-color: #fff;
  border-radius: 10px;
  margin: 20px 15px;

  > img {
    width: 100%;
    border-radius: 10px;
  }
}

/*
====> GOVERNMENT PROGRAMS
*/
.xvia-gov-programs__img_slide_container {
  display: flex;
  padding: 20px 0;
}

.xvia-gov-programs__container {
  height: 550px;
  text-align: center;
  background-color: var(--xvia-color-darken-blue);
  clip-path: ellipse(100% 80% at 50% 20%);
}

.xvia-gov-programs__container_gradient_final_slide {
  display: flex;
  justify-content: end;
}

.xvia-gov-programs__gradient_final_slide {
  position: absolute;
  width: 200px;
  margin: 0 0 0 auto;
  z-index: 99;
  height: 50%;
  background: linear-gradient(
    90deg,
    rgba(20, 57, 113, 0) 0%,
    rgba(20, 57, 113, 1) 100%
  );
  margin: 0 0 0 auto;
}

.xvia-gov-programs__wrapper {
  background-color: var(--xvia-color-darkness-blue);
}

/*
====> DADOS DO PORTAL DE SERVIÇOS
*/

.xvia-service-portal-data__container-items {
  display: flex;
  justify-content: space-between;
  text-align: center;
  margin: 0 0 5px 0;
}

.xvia-service-portal-data__container {
  display: flex;
  justify-content: space-between;
  text-align: center;
  background-color: var(--xvia-bg-gray);
}

.xvia-service-portal-data__block {
  display: flex;
  align-items: center;
}

.xvia-service-portal-data__text_block_icons {
  display: block;
  margin: 0 0 0 10px;
  text-align: start;

  > span {
    font-size: 14px;
    line-height: 15px;
    font-weight: 500;
    color: #202020;
    font-family: var(--xvia-font-family);
  }

  > p {
    font-weight: 600;
    font-family: var(--xvia-font-family);
    font-size: 50px;
    line-height: 40px;
    color: #143971;
    margin: 5px 0 0 0;
  }
}
.xvia-filtered-services-panel {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  align-content: space-between;

  div {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.46px;
    color: #7b7b7b;
  }
}

hr {
  margin: 40px 0;
  border-top: 2px solid #d0d0d0;
}

h4 {
  font-family: var(--xvia-font-family);
  color: #202020;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.46px;
  margin-top: 12px;
  margin-bottom: 4px;

  -webkit-transition: font-weight ease-out 0.3s;
  -moz-transition: font-weight ease-out 0.3s;
  -ms-transition: font-weight ease-out 0.3s;
  -o-transition: font-weight ease-out 0.3s;
  transition: font-weight ease-out 0.3s;
}

@keyframes slideDown {
  from {
    max-height: 0;
  }
  to {
    max-height: 1000px;
  }
}

@keyframes slideUp {
  from {
    max-height: 1000px;
  }
  to {
    max-height: 0;
  }
}

.xvia-filtered-services-panel__container--opening {
  animation: slideDown 0.5s ease-out forwards;
  overflow: hidden;
}

.xvia-filtered-services-panel__container--closing {
  animation: slideUp 0.5s ease-out forwards;
  overflow: hidden;
}

@media screen and (max-width: 768px) {
  .xvia-filtered-services-panel__container--opening {
    animation: slideDown 0.5s ease-out forwards;
    overflow: hidden;
  }

  .xvia-filtered-services-panel__container--closing {
    animation: slideUp 0.5s ease-out forwards;
    overflow: hidden;
  }
}

.xvia-filtered-services-panel__container__footer {
  display: flex;
  justify-content: end;
  align-items: center;

  button {
    margin-top: 24px;
    background-color: var(--xvia-color-primary);
    border: none;
    border-radius: 5px;
    padding: 14px 47px;

    cursor: pointer;

    font-family: var(--xvia-font-family);
    font-weight: 500;
    font-size: 14px;
    line-height: 15px;
    letter-spacing: 0.46px;
    color: white;

    -webkit-transition: font-weight ease-out 0.3s,
      background-color ease-out 0.3s;
    -moz-transition: font-weight ease-out 0.3s, background-color ease-out 0.3s;
    -ms-transition: font-weight ease-out 0.3s, background-color ease-out 0.3s;
    -o-transition: font-weight ease-out 0.3s, background-color ease-out 0.3s;
    transition: font-weight ease-out 0.3s, background-color ease-out 0.3s;
  }

  button:hover {
    font-weight: 700;
    background-color: #ff891b;
  }
}

/*
====> NOTICIAS
*/
.xvia-gov-news__material_card {
  margin: 0 0 20px 0;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px -6px 11px rgba(0, 0, 0, 0.02);
}

.xvia-gov-news__material_card_bigger {
  height: 100% !important;
}

.xvia-gov-news__img_slide {
  padding: 10px;
  border-radius: 10px;
  margin: 20px 15px;

  > img {
    width: 100%;
    border-radius: 10px;
  }
}

.xvia-gov-news__container_tabs_content {
  margin: 50px 0 0 0;
}

.MuiTabs-indicator {
  background: transparent !important;
}

.MuiTabs-flexContainer {
  flex-wrap: wrap !important;
}

.Mui-selected {
  color: #202020 !important;
  border-bottom: 2px solid #ff891b !important;
  z-index: 1 !important;
}

/*
====> COMPONENTE DIVIDER
*/

.divider-encapsulated-wrapper {
  position: relative;
  overflow: hidden;
}

.divider-encapsulated__divider-top {
  width: 100%;
  clip-path: ellipse(55% 80% at 50% 20%);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
}

.divider-encapsulated__content {
  padding: 24px 16px;
}

/*
====> COMPONENTE FALE CONOSCO
*/

.xvia-contact-us {
  height: auto;
  text-align: left;
  background-color: var(--xvia-color-darken-blue);
  position: relative;
  padding-top: 60px;
  padding-bottom: 20px;
  height: auto;
  z-index: 1;

  @media (max-width: 1240px) {
    padding-bottom: 40px;
  }
}

.xvia-contact-us__image {
  width: 100%;
  height: 60vh;
  background-size: contain;
  background-position: left center;
  background-repeat: no-repeat;
  border-radius: 16px;
  overflow: hidden;

  transform: scale(1.4);
  transform-origin: left center;
  position: relative;
  left: -100px;
  top: 10px;

  @media (min-width: 1240px) and (max-width: 1440px) {
    left: -80px;
    transform: scale(1.35);
  }
}

.xvia-contact-us__header {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 24px;
}

.xvia-contact-us__bar {
  width: 6px;
  height: 62px;
  background: #ff891b;
  box-shadow: -3px 3px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.xvia-contact-us__content {
  display: flex;
  align-items: center;
  gap: 16px;
}

.xvia-contact-us__title {
  font-family: var(--xvia-font-family);
  font-weight: 500;
  font-size: 26px;
  line-height: 36px;
  letter-spacing: 1px;
  color: var(--xvia-color-gray);
  margin-bottom: 8px;
  word-wrap: break-word;
}

.xvia-contact-us__subtitle {
  font-family: var(--xvia-font-family);
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  letter-spacing: 1px;
  color: var(--xvia-color-title-secondary);
  margin-bottom: 16px;
  word-wrap: break-word;
}

.xvia-contact-us__section {
  margin-bottom: 32px;
}

.xvia-contact-us__section-title {
  font-family: var(--xvia-font-family);
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.46px;
  color: var(--xvia-color-title-secondary);
  margin-bottom: 16px;
  word-wrap: break-word;
}

.xvia-contact-us__card {
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 16px;
  border-radius: 8px;
  background-color: #fff;
  color: var(--xvia-color-darken-blue);
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 100%;
}

.xvia-contact-us__card-icon {
  color: #fff;
}

.xvia-contact-us__card-label {
  font-weight: 500;
  color: var(--xvia-color-text);
  font-size: 14px;
  font-family: var(--xvia-font-family);
  line-height: 20px;
  letter-spacing: 0.46px;
  word-wrap: break-word;
}

.xvia-contact-us__card-value {
  color: var(--xvia-color-title);
  font-size: 14px;
  font-family: var(--xvia-font-family);
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.46px;
  word-wrap: break-word;
}

.xvia-page-banners {
  display: flex;
  weight: 100%;
  min-height: 250px;
  align-items: start;
  clip-path: ellipse(60% 75% at 50% 20%);
}

.xvia-page-banners-container {
  display: flex;
  flex-direction: column;
  weight: 100%;
  align-items: start;
  padding-top: 40px;
}

.xvia-page-banners-button svg {
  color: #fff;
  width: 50px;
  height: 50px;
}

.xvia-page-banners h2 {
  color: #fff;
  font-size: 50px;
  font-family: var(--xvia-font-family);
  font-weight: 600;
  margin: 0px;
}

.xvia-home-search__container_page_actions {
  display: flex;
  justify-content: space-between;
  margin: 40px 0;
}

.xvia-home-search__filter_btn {
  display: flex;
  align-items: center;
  margin: 0 0 0 20px;

  > button {
    background: transparent !important;
    border: none !important;
    box-shadow: none !important;
    color: var(--xvia-color-darkness-blue);
  }
}

.xvia-gov-news__container_slider_imgs {
  margin: 0;
}

.xvia-gov-news__slider_imgs {
  padding: 0 5px;
  border-radius: 10px;
  margin: 0;

  > img {
    width: 100%;
    border-radius: 10px;
    height: 140px;
    object-fit: cover;
  }
}

/* === MOBILE === */
@media (max-width: 767px) {
  .xvia-recommended-services__title {
    font-size: 40px;
  }

  .xvia-service-portal-data__container-items {
    flex-wrap: wrap;
    justify-content: space-around;
  }

  .xvia-service-portal-data__block {
    margin: 20px 0;
  }

  .xvia-page-banners {
    min-height: 120px;
    /* clip-path: ellipse(60% 75% at 50% 20%); */

    > h2 {
      color: #fff;
      margin: -40px 0 0 90px;
      font-size: 22px;
    }
  }

  .xvia-gov-news__container_slider_imgs {
    display: none;
  }
  #accessibility-buttons{
    position: fixed;
    max-width: 95vw;
    min-height: 40px;
    min-width: 40px;
    right: 0;
    top: calc(63px + 50%);
    transform: translateY(-50%);
    z-index: 2147483647 !important;
    margin: 7px !important;
  }
  #accessibility-buttons div{
    display: grid
  }
  #accessibility-buttons div button{
    margin-right: 0px;
    width: 40px;
    height: 40px;
    margin: 3px;
  }
}

